import t from 'counterpart'
import { Button, Form, InputGroup } from 'react-bootstrap'
import { placeOrder } from '../actions'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { PointsInput } from './PointsInput'
import Coupon from './Coupon'

// SummaryProp
export const Summary = ({ totals, cart, order, setOrder, review, setReview, pointsAvailable }) => {
    const store = useParams().store
    const [errors, setError] = useState('')
    const [cartHasGifts, setCartHasGifts] = useState(false)
    // steps feedback
    const contactInfoDone = order.name && order.phone && order.email

    const shippingDone =
        !cart.isShippable ||
        (order.shipping_method_id && (!order.addressAddress || order.address_id))

    const hasAdress = () => {
        if ((order.shippingMethod?.address_required || order.is_business) && !order.address_id)
            return false
        return true
    }
    const canContinue = !cart.isLoading && contactInfoDone && shippingDone && hasAdress()

    const includeCashback = !(window.portalSetting('commerce.cashback.type') === 'included')
    const canUsePoints = window.portalSetting('commerce.loyalty.activate')
    const subtotal = totals.total.toLocaleString()
    const total = includeCashback ? totals.total : totals.total - totals.totalCashBack

    const handleCouponChange = (coupon) => {
        setOrder((order) => ({
            ...order,
            coupon,
            coupon_code: coupon?.coupon_code,
        }))
    }

    useEffect(() => {
        cart?.items.map((item) => {
            if (item.gifts && item.gifts.length) {
                setCartHasGifts(true)
                return true
            }
        })
    }, [cart])

    const [HidePoints, setHidePoints] = useState(false)
    const [HideCoupons, setHideCoupons] = useState(false)

    useEffect(() => {
        if (review) {
            setHidePoints(!order?.points_cash)
            setHideCoupons(!order?.coupon_code)
        }
    }, [review])

    const ResetCouponsAndPoints = () => {
        setHidePoints(false)
        setHideCoupons(false)
    }

    const calcVat = () => {
        let v = ((totals.total * 100) / 115) * 0.15
        return v ? v.toFixed(2) : v
    }

    return (
        <div id="summary">
            {!HideCoupons && (
                <Coupon
                    review={review}
                    cartHasGifts={cartHasGifts}
                    onChange={handleCouponChange}
                    paymentMethodId={order.payment_method_id}
                />
            )}
            <div id="total">
                {canUsePoints &&
                pointsAvailable?.cash != null && // Ensure it's not null or undefined
                !isNaN(Number(pointsAvailable.cash)) && // Ensure it's a valid number
                Number(pointsAvailable.cash) > 0 &&
                !HidePoints ? (
                    <>
                        <div className="col-12 points">
                            <PointsInput
                                order={order}
                                pointsAvailable={pointsAvailable}
                                points_cash={order.points_cash || 0}
                                onChange={(value) =>
                                    setOrder((prev) => ({ ...prev, points_cash: value }))
                                }
                                disabled={(pointsAvailable.cash || 0) === 0 || review}
                            />
                        </div>
                    </>
                ) : null}

                {totals.totalSaved !== 0 && <h6 className="col-6 saved">{t('Total Saved')}</h6>}
                {totals.totalSaved !== 0 && (
                    <div className="col-6 saved"> {totals.totalSaved.toLocaleString()}</div>
                )}
                {!includeCashback && (
                    <>
                        {' '}
                        <h6 className="col-6">{t('subtotal')}</h6>
                        <div className="col-6"> {subtotal.toLocaleString()}</div>
                    </>
                )}
                <h6 className="col-6">{t('VAT')}</h6>
                <div className="col-6">
                    {calcVat().toLocaleString()}
                </div>
                {!includeCashback && (
                    <>
                        <h6 className="col-6">{t('Discount(Tax gift)')}</h6>
                        <div className="col-6"> {totals.totalCashBack.toLocaleString()}</div>
                    </>
                )}
                <h6 className="col-6">{t('Total')}</h6>
                <div className="col-6"> {(total - (order.points_cash || 0)).toLocaleString()}</div>
            </div>
            {!review && <CheckBoxes setOrder={setOrder} order={order} store={store} cart={cart} />}

            <Actions
                cart={cart}
                review={review}
                setReview={setReview}
                canContinue={canContinue}
                order={order}
                total={total}
                store={store}
                pointsAvailable={pointsAvailable}
                ResetCouponsAndPoints={ResetCouponsAndPoints}
            />
        </div>
    )
}

const CheckBoxes = ({ setOrder, order, store, cart }) => {
    return (
        <div className="terms-conds">
            <Form.Check
                id="terms"
                type="checkbox"
                checked={order.consent}
                onChange={(e) => setOrder({ ...order, consent: e.target.checked })}
                label={
                    <>
                        {t("I've read and agreed to the")}
                        <a href={`/${store}/terms`} target="_blank" rel="noreferrer">
                            {t('terms and conditions agreement')}
                        </a>
                    </>
                }
            />
            {cart.isShippable && (
                <Form.Check
                    id="shipping-terms"
                    type="checkbox"
                    checked={order.shipping_consent}
                    onChange={(e) => setOrder({ ...order, shipping_consent: e.target.checked })}
                    label={
                        <>
                            {t("I've read and agreed to the")}
                            <a href={`/${store}/delivery`} target="_blank" rel="noreferrer">
                                {t('shipping and installation agreement')}
                            </a>
                        </>
                    }
                />
            )}
        </div>
    )
}

const Actions = ({
    cart,
    setReview,
    canContinue,
    order,
    store,
    review,
    pointsAvailable,
    total,
    ResetCouponsAndPoints,
}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const onPlaceOrder = () => {
        let newOrder = { ...order }
        delete newOrder.shippingMethod
        delete newOrder.paymentMethod

        if (!cart.isLoading) {
            dispatch(placeOrder(newOrder, navigate, store))
        }
    }

    const handleBack = () => {
        ResetCouponsAndPoints()
        setReview(false)
    }

    if (review)
        return (
            <div style={{ display: 'flex' }}>
                <Button
                    className="continue"
                    onClick={() => handleBack()}
                    style={{ marginLeft: 5, marginRight: 5 }}
                >
                    {!cart.isLoading && <i className={'fa fa-chevron-' + t('left')} />}
                </Button>

                <Button className="continue" disabled={!canContinue} onClick={onPlaceOrder}>
                    {!cart.isLoading && (
                        <>
                            <i className="fa fa-money-bill" /> {t('Place Your Order')}
                        </>
                    )}
                    {cart.isLoading && (
                        <>
                            <i className="fa fa-spinner fa-spin" /> {t('Loading')}
                        </>
                    )}
                </Button>
            </div>
        )
    if (!review)
        return (
            <Button
                className="continue review"
                disabled={!canContinue}
                onClick={() => {
                    if (!order.consent || (cart.isShippable && !order.shipping_consent)) {
                        alert(t('you_must_agree_to_terms_before_you_can_proceed'))
                        return
                    }
                    if (order.points_cash > (pointsAvailable.cash || 0)) {
                        alert(t('not_enough_points'))
                        return
                    }
                    if (total - (order.points_cash || 0) < 0) {
                        alert(t('negative_total'))
                        return
                    }

                    setReview(true)
                }}
                // style={{ backgroundColor: '#10c600' }}
            >
                {t('Review Your Order')}
            </Button>
        )
}
