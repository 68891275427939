import { Button, Card } from 'react-bootstrap'
import t from 'counterpart'
import { forwardRef, useRef } from 'react'
import { useReactToPrint } from 'react-to-print'
import moment from 'moment'

export const PrintShippingDetails = (props) => {
    const componentRef = useRef(null)
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    })

    return (
        <>
            <ShippingLabelTemplate ref={componentRef} {...props} />
            <Button className="print-label-button" onClick={handlePrint}>
                {t('Print')}
            </Button>
        </>
    )
}

const ShippingLabelTemplate = forwardRef(({ order, orderDate }, ref) => {
    return (
        <div className="print-only" dir={t('dir')} ref={ref}>
            <h2 style={{ textAlign: 'center', padding: '24px' }}>{t('Order Details')}</h2>
            <OrderDetails />
            {!!order.address_id && <Address />}
            <Recipt />
        </div>
    )

    function OrderDetails() {
        return (
            <div style={{ padding: '24px' }}>
                <table width="100%">
                    <tbody>
                        <tr>
                            <th>{t('Date')}:</th>
                            <td>{orderDate}</td>
                        </tr>
                        <tr>
                            <th>{t(order.return ? 'Refund Number' : 'Order Number')}:</th>
                            <td>{order.id + 1000}</td>
                            <th>{t('Payment Method')}:</th>
                            <td>
                                <i
                                    className={
                                        !order.payment_method_id
                                            ? 'fa fa-money-bill'
                                            : 'fa fa-credit-card'
                                    }
                                />
                                {t(
                                    !order.payment_method_id ? 'Cash on delivery' : 'Online Payment'
                                )}
                            </td>
                        </tr>

                        <tr>
                            <th>{t('Total')}:</th>
                            <td>{order.total}</td>
                            <th>{t('paid')}:</th>
                            <td>
                                <i className={order.paid ? 'fa fa-check' : 'fa fa-times'} />
                                {order.paid && order.payment && moment(order.payment.at).from()}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
    function Address() {
        return (
            <div
                style={{
                    padding: '20px',
                    borderBottom: '2px dashed gray',
                    borderTop: '2px dashed gray',
                }}
            >
                <h4>{t('Address')}</h4>
                <table
                    style={{
                        borderCollapse: 'collapse',
                        width: '100%',
                    }}
                >
                    <tbody>
                        <tr>
                            <th style={{ paddingInlineEnd: '0px', marginBottom: '4px' }}>
                                {t('Country')}:{' '}
                            </th>
                            <td style={{ paddingInlineEnd: '14px' }}>
                                {new Intl.DisplayNames([t.getLocale()], {
                                    type: 'region',
                                }).of(order.country)}
                            </td>
                            <th style={{ paddingInlineEnd: '0px', marginBottom: '4px' }}>
                                {t('State')}:{' '}
                            </th>
                            <td style={{ paddingInlineEnd: '14px' }}>{order.state}</td>
                            <th style={{ paddingInlineEnd: '0px', marginBottom: '4px' }}>
                                {t('City')}:{' '}
                            </th>
                            <td style={{ paddingInlineEnd: '14px' }}>{order.city}</td>
                        </tr>
                        <tr>
                            <th style={{ paddingInlineEnd: '0px', marginBottom: '4px' }}>
                                {t('Street')}:{' '}
                            </th>
                            <td style={{ paddingInlineEnd: '14px' }}>{order.street}</td>
                        </tr>
                        <tr>
                            <th style={{ paddingInlineEnd: '0px', marginBottom: '4px' }}>
                                {t('Building')}:{' '}
                            </th>
                            <td style={{ paddingInlineEnd: '14px' }}>{order.building}</td>
                            <th style={{ paddingInlineEnd: '0px', marginBottom: '4px' }}>
                                {t('Floor')}:{' '}
                            </th>
                            <td style={{ paddingInlineEnd: '14px' }}>{order.floor}</td>
                            <th style={{ paddingInlineEnd: '0px', marginBottom: '4px' }}>
                                {t('Apartment')}:{' '}
                            </th>
                            <td style={{ paddingInlineEnd: '14px' }}>{order.apartment}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
    function Recipt() {
        return (
            <div style={{ padding: '20px' }}>
                <h4> {t('Invoice')}</h4>

                <table
                    style={{
                        width: '100%',
                        marginBottom: '32px',
                    }}
                >
                    <thead>
                        <tr style={{ borderBottom: '1px solid black' }}>
                            <th style={{}}>{t('Product')}</th>
                            <th style={{ textAlign: 'center' }}>{t('Quantity')}</th>
                            <th style={{ textAlign: 'center' }}>{t('Total')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {order.items?.map((item) => (
                            <tr style={{ borderBottom: '1px solid gray' }} key={item.id}>
                                <td style={{ fontSize: 10 }}>
                                    {item.product?.name} <br />
                                    <span>
                                        {t('price')} {item.price?.toLocaleString()}{' '}
                                        {t(order.currency)}{' '}
                                    </span>
                                    {!!item.options_price && (
                                        <span>
                                            / {t('Additional Options')} {item.options_price}{' '}
                                            {t(order.currency)}
                                        </span>
                                    )}
                                    <ul
                                        style={{
                                            margin: 0,
                                            padding: 0,
                                            listStyleType: 'none',
                                        }}
                                    >
                                        {item.options?.map((option) => (
                                            <li key={option.id}>
                                                {t(option.option_key || ' ')}{' '}
                                                {option.option_type === 'text' ? option.value : ''}
                                            </li>
                                        ))}
                                    </ul>
                                </td>
                                <td style={{ textAlign: 'center' }}>{item.quantity}</td>
                                <td style={{ textAlign: 'center' }}>
                                    {item.total?.toLocaleString()} {t(order.currency)}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <table style={{ borderCollapse: 'collapse' }}>
                    <tbody>
                        <tr>
                            <th style={{ paddingInlineEnd: '16px' }}>{t('Subtotal')}:</th>
                            <td>
                                {(
                                    order.total +
                                    (order.discount || 0) -
                                    (order.shipping_fees || 0)
                                )?.toLocaleString()}{' '}
                                {t(order.currency)}
                            </td>
                        </tr>
                        {!!order.discount && (
                            <tr>
                                <th style={{ paddingInlineEnd: '16px' }}>{t('Discount')}:</th>
                                <td>
                                    {order.discount} {t(order.currency)}
                                </td>
                            </tr>
                        )}
                        {!order.has_subscription && (
                            <tr>
                                <th style={{ paddingInlineEnd: '16px' }}>{t('Shipping Fees')}:</th>
                                <td>
                                    {order.shipping_fees
                                        ? order.shipping_fees + ' ' + t(order.currency)
                                        : t('Free')}
                                </td>
                            </tr>
                        )}
                        <tr>
                            <th style={{ paddingInlineEnd: '16px' }}>{t('VAT')}:</th>
                            <td>
                                {((order.total / 1.15) * 0.15)?.toLocaleString()}{' '}
                                {t(order.currency)}
                            </td>
                        </tr>
                        <tr style={{ borderTop: '1px solid gray', height: '64px' }}>
                            <th style={{ paddingInlineEnd: '16px' }}>{t('Total')}:</th>
                            <td>
                                {order.total?.toLocaleString()} {t(order.currency)}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
})
