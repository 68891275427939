import React, { useState, useEffect, Suspense } from 'react'
import { Routes, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Spinner } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import TagManager from 'react-gtm-module'
import AdminBar from './apps/Admin/AdminBar'
import { Capacitor } from '@capacitor/core'
import { loadUser, loadCategories, pushNotifications } from './actions'
import View from './Layout/View'
import api from './utilities/API'
import SafeZone from './Components/SafeZone'
import WarningDialog from './Components/WarningDialog'

// styles
import 'normalize.css'
import '@fortawesome/fontawesome-free/css/all.css'
import './Layout/style/Main.scss'

//
function Portal({ routes }) {
    const dispatch = useDispatch()
    const [queue, setQueue] = useState(null)
    const location = useLocation()
    const user = useSelector((state) => state.portal.user)

    //
    useEffect(() => {
        dispatch(loadUser())
        dispatch(loadCategories())
    }, [dispatch])
    //
    useEffect(() => {
        pushNotifications()

        // google tag manager
        const GAKey = window.portalSetting('web.google.analytics.key')
        if (GAKey) {
            TagManager.initialize({ gtmId: GAKey })
        }

        document.addEventListener('queue', function (e) {
            setQueue(e.detail)
        })
    }, [])

    //
    useEffect(() => {
        window.scrollTo(0, 0)
        document.getElementById('mobile_menu')?.classList.remove('active')

        if (window.dataLayer) {
            const url = window.location.pathname + window.location.search
            window.dataLayer.push({ event: 'pageview', page: { url } })
        }
    }, [location])

    useEffect(() => {
        // register push notification token
        if (!user || !window.push_notifications_token) {
            return
        }

        api('/notifications/tokens', {
            method: 'POST',
            data: { platform: window.platform, token: window.push_notifications_token },
            error: (error) => console.log(error),
        })
    }, [user])

    // maintenance
    const maintenance = window.portalSetting('core.maintenance.enabled') === 'true'
    if (maintenance && (!user || !user.can('cor.admin.access'))) {
        return (
            <div style={{ fontSize: 50, textAlign: 'center', margin: 50 }}>
                <img src="/assets/404.svg" width="500" alt="" />
                <h4>{window.portalSetting('core.maintenance.message')}</h4>
            </div>
        )
    }

    //
    const adminPage = location.pathname.includes('/admin')
    const posPage = location.pathname.includes('/pos')
    const QRProcessorPage = location.pathname.includes('/tables')
    const payLink = location.pathname.includes('/cart/pay/link')
    const fullPage =
        adminPage || posPage || payLink || QRProcessorPage || location.pathname.includes('/auth/')
    const body = (
        <div id="wrapper" key="main">
            {queue && <SafeZone data={queue} />}

            <Suspense
                fallback={
                    <div style={{ paddingTop: 100, display: 'flex', justifyContent: 'center' }}>
                        <Spinner animation="border" role="status" />
                    </div>
                }
            >
                <Routes className="switch-wrapper">{routes}</Routes>
            </Suspense>
        </div>
    )

    window.portal_interface = adminPage ? 'admin' : posPage ? 'pos' : 'user'

    const isAdmin = user && user.can('cor.admin.access')
    const appName = window.portalSetting('core.app_name')
    const appDesc = window.portalSetting('core.app_description')
    const shareURL = window.location.href
    const appImg = shareURL + '/logo.svg'
    const description = window.portalSetting('commerce.seo.description') || appDesc
    const keyword = window.portalSetting('commerce.seo.keyword') || appDesc

    const handlePreviewAdminBar = () => {
        if(!user) return <></> 
        if (isAdmin && !posPage) {
            return !QRProcessorPage &&<AdminBar />
        }
        return <></>
    }
    return (
        <div
            id="portal"
            className={(isAdmin ? 'is-admin ' : '') + ('platform-' + Capacitor.getPlatform())}
        >
            <Helmet titleTemplate={'%s - ' + appName}>
                <title>{appDesc || 'Welcome'}</title>

                {/* Primary Meta Tags  */}
                <meta name="title" content={appName} />
                <meta name="description" content={description || 'commerce website'} />
                <meta name="keyword" content={keyword ?? ''} />

                {/* Open Graph / Facebook  */}
                <meta property="og:type" content="website" />
                <meta property="og:url" content={shareURL} />
                <meta property="og:title" content={appName} />
                <meta property="og:description" content={description} />
                <meta property="og:image" content={appImg} />

                {/* Twitter */}
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content={shareURL} />
                <meta property="twitter:title" content={appName} />
                <meta property="twitter:description" content={description} />
                <meta property="twitter:image" content={appImg} />
            </Helmet>

            {handlePreviewAdminBar()}
{/* {isAdmin && !posPage && <AdminBar />} */}
            {fullPage ? body : <View name="layout" mainBody={body} />}

            <WarningDialog />
        </div>
    )
}

export default Portal
