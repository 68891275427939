import { Badge } from 'react-bootstrap'
import t from 'counterpart'

function ProductBadges({ product, externalStyles = false }) {
    const displayCashback = !!!window.portalSetting('commerce.cashback.display')
    const themesSettings = window.themesSettings('Blue')

    const discountBadgeType = themesSettings?.blocks?.savingLabel
    // const bothTypeDiscount = themesSettings?.blocks?.savingLabel === 'both'
    
    const renderDiscountBadge = () => {

        if (discountBadgeType) {
            return (
                <Badge bg="danger">
                    {discountBadgeType.includes("saved") ?  t('saved_amount') : t('Discount') }{' '}
                    {(discountBadgeType.includes("percent")) && (
                        <span className="percent">{product.saved_percent} ٪</span>
                    )}{' '}
                    {(discountBadgeType.includes("value")) && (
                        <span className="">
                            {product.saved_amount} {window.portalSetting('commerce.currency')}
                        </span>
                    )}
                </Badge>
            )
        }
        return <></>
    }

    return (
        <div
            className="badges"
            style={
                externalStyles
                    ? {}
                    : { position: 'absolute', display: 'flex', flexWrap: 'wrap', top: 0, right: 0 }
            }
        >
            {product.labels?.map((label, i) => (
                <Badge key={i} bg="success">
                    {label}{' '}
                </Badge>
            ))}
            {!!product.cash_back && displayCashback && (
                <Badge bg="warning">
                    {t('With %(amount)s %(currency)s cashback', {
                        amount: product.cash_back,
                        currency: window.portalSetting('commerce.currency'),
                    })}{' '}
                </Badge>
            )}
            {/* here */}
            {!!product.saved_amount && renderDiscountBadge()}
            {product.available_stock < 6 && (
                <Badge bg="info">
                    {t('Available')} {product.available_stock}{' '}
                </Badge>
            )}
            {!!product.gifts_ids && <Badge bg="info">{t('Gift')} </Badge>}
        </div>
    )
}

export default ProductBadges
