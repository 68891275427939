import { Card } from 'react-bootstrap'
import 'react-datepicker/dist/react-datepicker.css'
import { InfoSide } from './InfoSide'
import { MediaSide } from './MediaSide'
import ProductsCarousel from '../../Blocks/ProductsCarousel'
import * as t from 'counterpart'

export const MainDetails = ({ product, setProduct, store, shareURL, user }) => {
    return (
        <Card>
            <div className="row" id="details">
                <div className="col-md-6 info-side">
                    <InfoSide product={product} store={store} setProduct={setProduct} user={user} />
                </div>
                <div className="col-md-6" id="media-side">
                    <MediaSide product={product} shareURL={shareURL} store={store} />
                </div>
                {product.gifts && (
                    <ProductsCarousel
                        id="gifts"
                        title={t('Gift')}
                        cardsNumber={1}
                        cardsNumberMobile={1}
                        loadedProducts={product.gifts.map(p => ({...p, isGift: true}))}
                    />
                )}
            </div>
        </Card>
    )
}
