import { useState } from 'react'
import { Alert, Button, FormControl } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import * as t from 'counterpart'
import Datepicker from 'react-datepicker'

import ProductsCarousel from '../../Blocks/ProductsCarousel'
import { addToCart } from '../../../Cart/actions'
import { addToCompare } from '../../actions'

import PaymentHooks from '../../PaymentHooks'
import 'react-datepicker/dist/react-datepicker.css'
import { RatingAverage } from '../../components/RatingAverage'
import ProductBadges from '../../Blocks/ProductBadges'

const withCompare = process.env.REACT_APP_WITH_COMPARE === 'true'

export const InfoSide = ({ product, store, setProduct, user }) => {
    const [quantity, setQuantity] = useState(1)
    const [selectedAvailabilityDate, setSelectedAvailabilityDate] = useState(null)
    const [selectedAvailability, setSelectedAvailability] = useState(null)
    const [addToCartStatus, setAddToCartStatus] = useState('')

    const dispatch = useDispatch()
    const compare = useSelector((state) => state.commerce.compare)
    const compareIdx = compare.data.indexOf(product?.id)
    const inCompare = compareIdx !== -1

    // addToCartLabel
    let addToCartLabel = t('Add to Cart')
    if (addToCartStatus === 'loading') addToCartLabel = t('Adding to Cart')
    if (addToCartStatus === 'success') addToCartLabel = t('Added')

    const toggleCompare = () => {
        let newList = [...compare.data]
        if (inCompare) {
            newList.splice(compareIdx, 1)
        } else {
            newList.push(product.id)
        }

        dispatch(addToCompare(newList.slice(-4)))
    }

    const onAddToCart = () => {
        setAddToCartStatus('loading')

        let item = {
            product_id: product.id,
            quantity,
            options: Object.values(product.selectedOptions || {}),
        }
        if (selectedAvailability) {
            item.reserved_from = selectedAvailability.available_from
            item.reserved_to = selectedAvailability.available_to
        }

        if (!user) {
            item.productData = product
        }

        dispatch(
            addToCart({
                data: [item],
                after: (status, error) => {
                    if (error) {
                        status = { error }
                    }
                    setAddToCartStatus(status)
                },
                local: !user,
                product,
            })
        )
    }

    const handleOptionsCheckbox = (e, option) => {
        let newProduct = {
            ...product,
        }
        if (e.target.checked) {
            newProduct.selectedOptions = {
                ...product.selectedOptions,
                [option.id]: {
                    id: option.id,
                    key: option.key,
                    value: 'yes',
                },
            }
        } else {
            delete newProduct.selectedOptions[option.id]
        }

        setProduct(newProduct)
    }
    const displayCashback = !!window.portalSetting('commerce.cashback.display')
    const currentPrice =
        displayCashback && product.cash_back
            ? product.current_price - product.cash_back
            : product.current_price
    return (
        <div>
            <ProductBadges product={product} externalStyles={true} />

            {product.brand && (
                <Link className="brand" to={`/${store}/browse?brand_id[]=` + product.brand.id}>
                    <img src={product.brand.image} alt={product.brand.name} />
                    <div className="brand-name"> {product.brand.name}</div>
                </Link>
            )}

            <h1>{product.name}</h1>

            <div className="code-rating-wrapper">
                <div>
                    <span className="code">
                        <div>{t('Model code')}:</div> <div>{product.code}</div>
                    </span>
                </div>
                <div>|</div>
                <RatingAverage product={product} />
            </div>
            {/* {product.seller && (
                <div id="seller">
                    {t('By')}{' '}
                    <Link to={'/' + store + '/browse?seller_id=' + product.seller_id}>
                        {product.seller.name}
                    </Link>
                </div>
            )} */}

            <div className="price-box">
                {product.type === 'parent' && <span>{t('Starting From')}</span>}
                {(!!product.saved_amount || (product.cash_back && displayCashback)) ? (
                    <div className="old-price">
                        <div>{t('was')}: </div>
                        <div className="value">
                            {product.price.toLocaleString()}{' '}
                            {window.portalSetting('commerce.currency')}
                        </div>
                    </div>
                ) : null}
                {!!product.saved_amount && !!product.cash_back && !!displayCashback && (
                    <div className="old-price">
                        <div>{t('was')}: </div>
                        <div className="value">
                            {product.current_price.toLocaleString()}{' '}
                            {window.portalSetting('commerce.currency')}
                        </div>
                    </div>
                )}
                {!!product.current_price && (
                    <div className="price">
                        {!product?.price && <div>{t('after')}: </div>}
                        <div>
                            {currentPrice.toLocaleString()}{' '}
                            <span>{window.portalSetting('commerce.currency')}</span>
                        </div>
                        {!!product.saved_percent && (
                            <div className="saved">
                                {t('Discount')} {product.saved_percent}٪{' '}
                            </div>
                        )}
                    </div>
                )}
                {!product.current_price && (
                    <div className="price">
                        <span>{t('Free')}</span>
                    </div>
                )}
                {!!product.saved_amount && (
                    <div className="saved-amount">
                        {t('saved_amount') + ':' + ' ' + product.saved_amount.toLocaleString()}{' '}
                        {window.portalSetting('commerce.currency')}
                        {/*<Countdown time={product.special_to} />*/}
                    </div>
                )}
                {/*{product.current_price !== 0 && <div className="note">{t("VAT Included")}</div>}*/}
            </div>

            {product.type !== 'parent' && (
                <div
                    className={'add-to-cart ' + (product.availabilities ? 'with-availability' : '')}
                >
                    {product.options && (
                        <div id="options">
                            {product.options.map((option) => {
                                const price = option.price - option.discount
                                return (
                                    <label key={option.id}>
                                        <div className="options">
                                            <div>
                                                <input
                                                    type="checkbox"
                                                    value={
                                                        product.selectedOptions?.[option.id] ||
                                                        false
                                                    }
                                                    onChange={(e) =>
                                                        handleOptionsCheckbox(e, option)
                                                    }
                                                />
                                            </div>

                                            <div className="name">{option.name}</div>

                                            <div className="price">
                                                {!!parseFloat(option.discount) && (
                                                    <div
                                                        style={{
                                                            textDecoration: 'line-through',
                                                            margin: '0 5px',
                                                        }}
                                                    >
                                                        {option.price}{' '}
                                                        {window.portalSetting('commerce.currency')}
                                                    </div>
                                                )}
                                                <div>
                                                    {price
                                                        ? '+' +
                                                          price +
                                                          ' ' +
                                                          window.portalSetting('commerce.currency')
                                                        : t('Free')}
                                                </div>
                                            </div>
                                        </div>
                                    </label>
                                )
                            })}
                        </div>
                    )}

                    {product.availabilities && (
                        <div id="availabilities">
                            <h6>{t('Appointment Booking')}</h6>

                            <Datepicker
                                inline
                                includeDates={product.availabilitiesDates}
                                selected={selectedAvailabilityDate}
                                onChange={(date, e) => setSelectedAvailabilityDate(date)}
                            />

                            {(!product.availabilities || selectedAvailabilityDate) &&
                                (
                                    product.availabilities[
                                        selectedAvailabilityDate.toISOString().split('T')[0]
                                    ] || []
                                ).map((availability) => (
                                    <button
                                        key={availability.id}
                                        onClick={() => setSelectedAvailability(availability)}
                                        className={
                                            selectedAvailability &&
                                            availability.id === selectedAvailability.id
                                                ? 'selected'
                                                : ''
                                        }
                                    >
                                        {new Date(availability.available_from).toLocaleTimeString()}
                                    </button>
                                ))}
                        </div>
                    )}

                    {(!product.availabilities || selectedAvailability) && (
                        <div style={{ display: 'flex' }}>
                            {product.available_stock ? (
                                <>
                                    {addToCartStatus && addToCartStatus.error && (
                                        <Alert variant="danger">{addToCartStatus.error}</Alert>
                                    )}
                                    {!product.availabilities && (
                                        <div className="quantity">
                                            <FormControl
                                                type="number"
                                                min="1"
                                                value={quantity}
                                                onChange={(e) =>
                                                    setQuantity(parseInt(e.target.value))
                                                }
                                            />
                                        </div>
                                    )}
                                    <Button
                                        onClick={onAddToCart}
                                        variant={addToCartStatus || 'primary'}
                                        disabled={addToCartStatus === 'loading'}
                                    >
                                        <i className="fa fa-cart-shopping" /> {addToCartLabel}
                                    </Button>
                                </>
                            ) : (
                                <Button variant="danger" disabled>
                                    {t('Out of stock')}
                                </Button>
                            )}

                            {withCompare && (
                                <Button
                                    className="add-to-compare"
                                    variant={inCompare ? 'success' : 'info'}
                                    onClick={toggleCompare}
                                >
                                    <i className="fa fa-check-double" /> <div>{t('Compare')}</div>
                                </Button>
                            )}
                        </div>
                    )}
                </div>
            )}

            <h6 className="details-text" style={{ margin: 0 }}>
                {window.portalSetting('commerce.product_details_text_' + t.getLocale())}
            </h6>
            <PaymentHooks product={product} />
            {product.gifts && (
                <ProductsCarousel
                    id="gifts"
                    title={t('Gift')}
                    cardsNumber={2}
                    cardsNumberMobile={2}
                    loadedProducts={product.gifts.map(p => ({...p, isGift: true}))}
                />
            )}
        </div>
    )
}
