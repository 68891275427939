import React, { useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import t from 'counterpart'
import api from '../../../utilities/API'

type CouponProps = {
    onChange: (data: any) => void
    paymentMethodId?: string
    className?: string
    cartHasGifts? : boolean
    review?: boolean
}

type CouponState = {
    input?: string
    code?: string
    discount?: number
    percent?: boolean
    disable_gifts?: boolean;
    error?: string | null
}

function Coupon({ cartHasGifts=false, onChange, className, paymentMethodId, review=false }: CouponProps) {
    const [coupon, setCoupon] = useState<CouponState>({})

    //
    const checkCoupon = () => {
        api('commerce/coupons/check/code', {
            method: 'POST',
            data: { code: coupon.input, payment_method_id: paymentMethodId },
            result: (data: any) => {
                setCoupon({
                    ...coupon,
                    error: null,
                    code: data.coupon_code,
                    discount: data.discount,
                    percent: data.percent,
                    disable_gifts: data.disable_gifts,
                })
                onChange(data)
            },
            error: (error: any) => {
                if (error === 'Coupon not valid') {
                    error = t(error)
                }

                setCoupon({ ...coupon, error })
            },
        })
    }

    return (
        <div id="coupon" className={className}>
            <h3>
                <span>٪</span> {t('Discount Code')}
            </h3>
            <div className="content">
                <div className="col-12 col-sm-7">
                    <Form.Group controlId="coupon_code">
                        <Form.Control
                            name="coupon"
                            placeholder={t('Discount Code')}
                            value={coupon.input || ''}
                            disabled={!!coupon.code}
                            onChange={(e) => setCoupon({ input: e.target.value })}
                        />
                    </Form.Group>
                </div>
                <div className="col-10 col-sm-4">
                    {!coupon.code  && !review && (
                        <Button className="" onClick={checkCoupon}>
                            {t('Add Code')}
                        </Button>
                    )}
                    {coupon.code && !review &&  (
                        <Button
                            onClick={() => {
                                setCoupon({})
                                onChange(null)
                            }}
                            variant="danger"
                        >
                            {t('Remove The Code')}
                        </Button>
                    )}
                </div>
            </div>
            <div className="error">{coupon.error}</div>
            {coupon.code && (
                <div className="success">
                    {t('discount')} {coupon.discount}{' '}
                    {t(window.portalSetting('commerce.currency'))}
                    {/* {coupon.percent ? '%' : t(window.portalSetting('commerce.currency'))}{" "} */}
                    <br />
                    {cartHasGifts && coupon.disable_gifts ? t('giftsWillBeCanceled') : ""}
                </div>
            )}
        </div>
    )
}

export default Coupon
