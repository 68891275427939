import { Alert, Button, Form } from 'react-bootstrap'
import t from 'counterpart'
import { useCallback, useRef, useState } from 'react'
import API from '../../../../utilities/API'
import { AddressFormProps, ErrorsStateType } from './types'
import { Autocomplete, GoogleMap, LoadScript, Marker } from '@react-google-maps/api'
import { parseAddress } from './Addresses'

const mapLibs: ('places' | 'geometry' | 'drawing' | 'localContext' | 'visualization')[] = ['places']

export const AddressForm = ({
    selectedId,
    setNewAddressForm,
    newAddressForm,
    onAddressFormChange,
    onSubmitAddressSucess,
    is_order_business,
}: AddressFormProps) => {
    const [errors, setErrors] = useState<ErrorsStateType>({ validation: {} })
    const [loading, setLoading] = useState(false)

    const autoRef = useRef<google.maps.places.Autocomplete | null>(null)
    const apiKey = window.portalSetting('web.google.maps.key')

    // add new address
    const onNewAddress = () => {
        setErrors({ validation: {} })
        setLoading(true)
        API('commerce/addresses/', {
            method: 'POST',
            data: { ...newAddressForm, is_business: is_order_business },
            result: (data: any) => {
                setLoading(false)
                onSubmitAddressSucess(data)
            },
            error: (error: any, details: any) => {
                setLoading(false)
                if (details.response && details.response.status === 422)
                    error = details.response.data

                setErrors({ validation: {}, ...error })
            },
        })
    }

    const onUpdateAddress = () => {
        setErrors({ validation: {} })
        setLoading(true)
        API(`commerce/addresses/${newAddressForm.id}`, {
            method: 'PUT',
            data: { ...newAddressForm, is_business: false },
            result: (data: any) => {
                setLoading(false)
                onSubmitAddressSucess(data)
            },
            error: (error: any, details: any) => {
                setLoading(false)
                if (details.response && details.response.status === 422)
                    error = details.response.data
                setErrors({ validation: {}, ...error })
            },
        })
    }

    //

    //

    const onMapClick = useCallback(
        (e: any) => {
            const location = { lat: e.latLng.lat(), lng: e.latLng.lng() }
            new window.google.maps.Geocoder().geocode(
                e.placeId ? { placeId: e.placeId } : { location },
                (p) => {
                    if (p) {
                        const addr = parseAddress(p[0])
                        if (addr.country && addr.state && addr.city) {
                            setNewAddressForm((prevSelected: any) => ({
                                ...prevSelected,
                                ...location,
                                name: addr.name,
                                country: addr.country,
                                state: addr.state,
                                city: addr.city,
                            }))
                        }
                    }
                }
            )
        },
        [selectedId]
    )

    //

    const autocompletePlace = () => {
        const p = autoRef.current?.getPlace()
        if (p?.geometry) {
            const l = p.geometry.location
            const addr = parseAddress(p)
            if (addr.country && addr.state && addr.city) {
                setNewAddressForm((prevState: any) => ({
                    ...prevState,
                    // ...addr,
                    name: addr.name,
                    country: addr.country,
                    state: addr.state,
                    city: addr.city,
                    lat: l?.lat(),
                    lng: l?.lng(),
                }))
            }
        }
    }

    const currentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                onMapClick({
                    latLng: {
                        lat: () => position.coords.latitude,
                        lng: () => position.coords.longitude,
                    },
                })
            })
        }
    }
    //

    return (
        <LoadScript googleMapsApiKey={apiKey} libraries={mapLibs}>
            <GoogleMap
                mapContainerStyle={{ width: '100%', height: '400px' }}
                center={{
                    lat: newAddressForm.lat || 23.1010706,
                    lng: newAddressForm.lng || 44.2407631,
                }}
                zoom={5.4}
                onClick={onMapClick}
            >
                {newAddressForm.lat && newAddressForm.lng && (
                    <Marker position={{ lat: newAddressForm.lat, lng: newAddressForm.lng }} />
                )}

                <Autocomplete
                    onLoad={(a) => (autoRef.current = a)}
                    onPlaceChanged={autocompletePlace}
                >
                    <input
                        type="text"
                        placeholder="Search for a place"
                        style={{
                            boxSizing: `border-box`,
                            border: `1px solid transparent`,
                            width: `240px`,
                            height: `40px`,
                            padding: `0 12px`,
                            borderRadius: `3px`,
                            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                            fontSize: `14px`,
                            outline: `none`,
                            textOverflow: `ellipses`,
                            position: 'absolute',
                            right: '64px',
                            top: '10px',
                            marginLeft: '-120px',
                        }}
                    />
                </Autocomplete>

                <Button
                    onClick={currentLocation}
                    style={{ position: 'absolute', bottom: 10, left: 10 }}
                    variant="light"
                >
                    <i className="fa fa-location-crosshairs" /> {t('Current Location')}
                </Button>
            </GoogleMap>

            {(!newAddressForm.lat || !newAddressForm.lng) && (
                <div className="d-flex align-items-center justify-content-center">
                    <h3>{t('Select your address on the map')}</h3>
                </div>
            )}
            <div className="mt-3">
                {errors.message && <Alert variant="danger">{t(errors.message)}</Alert>}
                <Form.Group controlId="address_name">
                    <Form.Control
                        name="name"
                        value={newAddressForm.name || ''}
                        onChange={onAddressFormChange}
                    />
                    <div className="error">{t(errors?.validation?.name || ' ')}</div>
                </Form.Group>
                <div className="row" style={{ marginTop: 10 }}>
                    <Form.Group controlId="country" className="col-md-4">
                        <Form.Label>{t('Country')}</Form.Label>
                        <Form.Control
                            name="country"
                            value={
                                newAddressForm.country
                                    ? new Intl.DisplayNames([t.getLocale()], {
                                          type: 'region',
                                      }).of(newAddressForm.country)
                                    : ''
                            }
                            disabled
                            onChange={onAddressFormChange}
                        />
                        <div className="error">{t(errors?.validation?.country || ' ')}</div>
                    </Form.Group>

                    <Form.Group controlId="state" className="col-md-4">
                        <Form.Label>{t('State')}</Form.Label>
                        <Form.Control
                            name="state"
                            value={newAddressForm.state || ''}
                            disabled
                            onChange={onAddressFormChange}
                        />
                        <div className="error">{t(errors?.validation?.state || ' ')}</div>
                    </Form.Group>

                    <Form.Group controlId="city" className="col-md-4">
                        <Form.Label>{t('City')}</Form.Label>
                        <Form.Control
                            name="city"
                            value={newAddressForm.city || ''}
                            disabled
                            onChange={onAddressFormChange}
                        />
                        <div className="error">{t(errors?.validation?.city || ' ')}</div>
                    </Form.Group>
                </div>

                <Form.Group controlId="neighborhood">
                    <Form.Label>{t('Neighborhood')}</Form.Label>
                    <Form.Control
                        name="neighborhood"
                        value={newAddressForm.neighborhood || ''}
                        onChange={onAddressFormChange}
                    />
                    <div className="error">{t(errors?.validation?.neighborhood || ' ')}</div>
                </Form.Group>

                <Form.Group controlId="street">
                    <Form.Label>{t('Street')}</Form.Label>
                    <Form.Control
                        name="street"
                        value={newAddressForm.street || ''}
                        onChange={onAddressFormChange}
                    />
                    <div className="error">{t(errors?.validation?.street || ' ')}</div>
                </Form.Group>
                {is_order_business && (
                    <>
                        <div className="row" style={{ marginTop: 10 }}>
                            <Form.Group controlId="postal_code" className="col-md-6">
                                <Form.Label>{t('Postal Code')}</Form.Label>
                                <Form.Control
                                    name="postal_code"
                                    value={newAddressForm.postal_code || ''}
                                    onChange={onAddressFormChange}
                                />
                                <div className="error">
                                    {t(errors?.validation?.postal_code || ' ')}
                                </div>
                            </Form.Group>
                            <Form.Group controlId="plot_number" className="col-md-6">
                                <Form.Label>{t('Plot Number')}</Form.Label>
                                <Form.Control
                                    name="plot_number"
                                    value={newAddressForm.plot_number || ''}
                                    onChange={onAddressFormChange}
                                />
                                <div className="error">
                                    {t(errors?.validation?.plot_number || ' ')}
                                </div>
                            </Form.Group>
                        </div>
                        <div className="row" style={{ marginTop: 10 }}>
                            <Form.Group controlId="registry_name" className="col-md-6">
                                <Form.Label>{t('Registry Name')}</Form.Label>
                                <Form.Control
                                    name="registry_name"
                                    value={newAddressForm.registry_name || ''}
                                    onChange={onAddressFormChange}
                                />
                                <div className="error">
                                    {t(errors?.validation?.registry_name || ' ')}
                                </div>
                            </Form.Group>
                            <Form.Group controlId="tax_number" className="col-md-6">
                                <Form.Label>{t('Tax Number')}</Form.Label>
                                <Form.Control
                                    name="tax_number"
                                    value={newAddressForm.tax_number || ''}
                                    onChange={onAddressFormChange}
                                />
                                <div className="error">
                                    {t(errors?.validation?.tax_number || ' ')}
                                </div>
                            </Form.Group>
                            <Form.Group controlId="registry_number" className="col-md-6">
                                <Form.Label>{t('Registry Number')}</Form.Label>
                                <Form.Control
                                    name="registry_number"
                                    value={newAddressForm.registry_number || ''}
                                    onChange={onAddressFormChange}
                                />
                                <div className="error">
                                    {t(errors?.validation?.registry_number || ' ')}
                                </div>
                            </Form.Group>
                        </div>
                    </>
                )}
                <div className="row" style={{ marginTop: 10 }}>
                    <Form.Group controlId="building" className="col-md-4">
                        <Form.Label>{t('Building')}</Form.Label>
                        <Form.Control
                            name="building"
                            value={newAddressForm.building || ''}
                            onChange={onAddressFormChange}
                        />
                        <div className="error">{t(errors?.validation?.building || ' ')}</div>
                    </Form.Group>

                    <Form.Group controlId="floor" className="col-md-4">
                        <Form.Label>{t('Floor')}</Form.Label>
                        <Form.Control
                            name="floor"
                            value={newAddressForm.floor || ''}
                            onChange={onAddressFormChange}
                        />
                        <div className="error">{t(errors?.validation?.floor || ' ')}</div>
                    </Form.Group>

                    <Form.Group controlId="apartment" className="col-md-4">
                        <Form.Label>{t('Apartment')}</Form.Label>
                        <Form.Control
                            name="apartment"
                            value={newAddressForm.apartment || ''}
                            onChange={onAddressFormChange}
                        />
                        <div className="error">{t(errors?.validation?.apartment || ' ')}</div>
                    </Form.Group>
                </div>
                <Form.Group
                    controlId="default"
                    className="switch-wrapper-label"
                    style={{ marginTop: 10 }}
                >
                    <Form.Check
                        name="default"
                        type="switch"
                        checked={newAddressForm.default || false}
                        onChange={(e) =>
                            onAddressFormChange({
                                target: { name: 'default', value: e.target.checked },
                            })
                        }
                    />
                    <Form.Label>{t('Set as default address')}</Form.Label>
                </Form.Group>
                {newAddressForm.id ? (
                    <Button
                        variant="danger"
                        onClick={onUpdateAddress}
                        style={{ marginTop: 10 }}
                        disabled={loading}
                    >
                        {t('Edit Address')}
                    </Button>
                ) : (
                    <Button
                        variant="danger"
                        onClick={onNewAddress}
                        style={{ marginTop: 10 }}
                        disabled={loading}
                    >
                        {t('Save Address')}
                    </Button>
                )}
            </div>
        </LoadScript>
    )
}
