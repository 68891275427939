import React, { useEffect, useState } from 'react'
import { Badge, Button, Modal } from 'react-bootstrap'
import { PaymentModals } from './types'
import t from 'counterpart'

import './style/SummaryModal.scss'
import Tips from './Tips'

type DividedModalProps = {
    PaymentModalType: PaymentModals | ''
    setPaymentModalType: Function
    order: any
    setTotalOrder: any
}
const DividedModal = ({
    PaymentModalType,
    setPaymentModalType,
    order,
    setTotalOrder,
}: DividedModalProps) => {
    const [TotalPeople, setTotalPeople] = useState<number>(1)
    const [SomePeople, setSomePeople] = useState<number>(1)
    const [TotalShare, setTotalShare] = useState<number>(0)

    useEffect(() => {
        if (TotalPeople && SomePeople && order) {
            setTotalShare((order.total / TotalPeople) * SomePeople)
        }
    }, [TotalPeople, SomePeople])

    const previewPrice = (price: number) => {
        const strPrice = price.toString().split('.')
        if (strPrice.length === 1) return strPrice
        return strPrice[0] + '.' + strPrice[1].slice(0, 2)
    }

    const handleConfirmDevidedModal = () => {
        setTotalOrder(() => TotalShare);
        setPaymentModalType('')
    }
    return (
        <div className="summary-modal divide-modal">
            <Modal
                show={PaymentModalType === PaymentModals.DIVIDE}
                dialogClassName="modal-90w m-auto"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header>
                    <div className="modal__header">
                        <div className="modal__header--title">
                            <span className="mb-1">{t('divideTheBillEqually')} </span>
                        </div>
                        <button
                            className="btn btn-close"
                            onClick={() => setPaymentModalType('')}
                        ></button>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <ul className="divided__list">
                        <li className="divided__list--item divided__option mb-2">
                            <div className="divided__option--info">
                                <Badge className="m-1" pill bg="secondary">
                                    <i className="fa fa-user" />
                                </Badge>
                                <small className="line-clamp-1">{t('totalPeopleInTable')}</small>
                            </div>
                            <div className="divided__option--counter">
                                <Badge
                                    pill
                                    bg="info"
                                    onClick={() =>
                                        TotalPeople > 1 &&
                                        TotalPeople > SomePeople &&
                                        setTotalPeople(TotalPeople - 1)
                                    }
                                >
                                    <i className="fa fa-minus" />
                                </Badge>
                                <small>{TotalPeople}</small>
                                <Badge
                                    pill
                                    bg="info"
                                    onClick={() => TotalPeople && setTotalPeople(TotalPeople + 1)}
                                >
                                    <i className="fa fa-plus" />
                                </Badge>
                            </div>
                        </li>
                        <li className="divided__list--item divided__option mb-2">
                            <div className="divided__option--info">
                                <Badge className="m-1" pill bg="secondary">
                                    <i className="fa fa-user" />
                                </Badge>
                                <small className="line-clamp-1">{t('peopleYouPayFor')}</small>
                            </div>
                            <div className="divided__option--counter">
                                <Badge
                                    pill
                                    bg="info"
                                    onClick={() => SomePeople > 1 && setSomePeople(SomePeople - 1)}
                                >
                                    <i className="fa fa-minus" />
                                </Badge>
                                <small>{SomePeople}</small>
                                <Badge
                                    pill
                                    bg="info"
                                    onClick={() =>
                                        SomePeople &&
                                        SomePeople < TotalPeople &&
                                        setSomePeople(SomePeople + 1)
                                    }
                                >
                                    <i className="fa fa-plus" />
                                </Badge>
                            </div>
                        </li>
                    </ul>
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <div className="divided__footer w-100">
                        <div className="d-flex w-100 justify-content-between text-secondary mb-2">
                            <small>{t('total')}</small>
                            <small>
                                {t(order?.currency)} {order?.total}
                            </small>
                        </div>
                        <div className="h6 d-flex w-100 justify-content-between">
                            <small>{t('yourShare')}</small>
                            <small>
                                {t(order?.currency)} {previewPrice(TotalShare)}
                            </small>
                        </div>
                        <div className="d-flex justify-content-between mt-2 gap-2 p-0">
                            <Button
                                variant="outline-danger"
                                className="flex-grow-1 w-50"
                                size="sm"
                                onClick={() => setPaymentModalType('')}
                            >
                                {t('removeSplit')}
                            </Button>
                            <Button
                                variant="primary"
                                className="flex-grow-1 w-50"
                                size="sm"
                                onClick={() => handleConfirmDevidedModal()}
                            >
                                {t('confirm')}
                            </Button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default DividedModal
