import React, { useEffect, useImperativeHandle, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, NavLink, useNavigate, useParams } from 'react-router-dom'
import { Badge, Button, Dropdown, Offcanvas } from 'react-bootstrap'
import * as t from 'counterpart'
import { loadCart, toggleOffCanvasCart } from '../actions'
import { ReactComponent as Icon } from './style/cart.svg'
import './style/CartPopup.scss'

let imagePrefix = process.env.REACT_APP_IMAGES_PREFIX || ''

export default React.forwardRef(function (props = {}, ref) {
    const dispatch = useDispatch()
    const { store } = useParams()
    const dropdownRef = useRef(null)
    const navigate = useNavigate()
    const direct = props.direct

    useImperativeHandle(ref, () => ({
        dropdown: dropdownRef.current,
    }))

    const { user, ready, items, offCanvasCart } = useSelector(({ cart, portal }) => {
        return {
            user: portal.user,
            ready: portal.ready,
            items: cart.items,
            offCanvasCart: cart.offCanvasCart,
        }
    })

    props = {
        ...props,
        className: 'cart-popup-block ' + (props.className || ''),
    }

    delete props.direct

    useEffect(() => {
        if (ready) {
            dispatch(loadCart(!user))
        }
    }, [user, ready])

    const hideCanvas = () => {
        dispatch(toggleOffCanvasCart(false))
    }

    const handleNavigateToCart = () => {
        dropdownRef.current.click()
        navigate(`/${store}/cart`)
    }

    const displayCashback = !!window.portalSetting('commerce.cashback.display')
    return (
        <>
            <Dropdown
                align="end"
                {...props}
                ref={dropdownRef}
                as={direct ? NavLink : 'div'}
                to={`/${store}/cart`}
            >
                <Dropdown.Toggle>
                    <span className="label">{t('Cart')}</span>
                    <Icon />
                    {items.length !== 0 && <Badge variant="light">{items.length}</Badge>}
                </Dropdown.Toggle>

                {!direct && (
                    <Dropdown.Menu>
                        {items.map((item) => (
                            <Dropdown.Item
                                key={item.id}
                                to={`/${store}/details/${item.slug}-${item.id}`}
                                as={Link}
                                disabled
                            >
                                <div
                                    className="image"
                                    style={{
                                        backgroundImage:
                                            "url('" + imagePrefix + item.thumbnail + "')",
                                    }}
                                />
                                <div>
                                    {item.name}
                                    <div>
                                        {' '}
                                        {t('Quantity')}: {item.cart_quantity} / {t('Price')}:{' '}
                                        {item.cart_quantity *
                                            (displayCashback && item.cash_back
                                                ? item.current_price - item.cash_back
                                                : item.current_price) +
                                            parseFloat(item.cart_options_price || 0)}
                                        {item.saved_amount ||
                                        (item.cash_back && displayCashback) ? (
                                            <span className="old-price">
                                                {(item.price * item.cart_quantity).toLocaleString()}
                                            </span>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </div>
                            </Dropdown.Item>
                        ))}

                        {items.length !== 0 && (
                            <Button
                                as={Link}
                                to={`/${store}/cart`}
                                onClick={handleNavigateToCart}
                                variant="outline-primary"
                                style={{ display: 'block' }}
                            >
                                {t('Browse your cart')}
                            </Button>
                        )}

                        {items.length === 0 && (
                            <div className="empty">{t('There are no items in your cart')}</div>
                        )}
                    </Dropdown.Menu>
                )}
            </Dropdown>

            <Offcanvas id="cart-offcanvas" show={offCanvasCart} onHide={hideCanvas}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{t('Cart')}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {items.map((item) => {
                        let price =
                            (displayCashback && item.cash_back
                                ? item.current_price - item.cash_back
                                : item.current_price) + parseFloat(item.cart_options_price || 0)

                        return (
                            <div key={item.id} className="row" style={{ marginBottom: 15 }}>
                                <div
                                    className="col-md-4"
                                    style={{
                                        height: 100,
                                        background:
                                            "url('" +
                                            imagePrefix +
                                            item.thumbnail +
                                            "') center no-repeat",
                                        backgroundSize: 'contain',
                                    }}
                                />
                                <div className="col-md-8">
                                    {item.name}
                                    <div>
                                        {' '}
                                        {t('Quantity')}: {item.cart_quantity} / {t('Price')}:{' '}
                                        {item.cart_quantity * price}
                                        {item.saved_amount ||
                                        (item.cash_back && displayCashback) ? (
                                            <span className="old-price">
                                                {(item.price * item.cart_quantity).toLocaleString()}
                                            </span>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </div>
                            </div>
                        )
                    })}

                    <Button
                        as={Link}
                        onClick={hideCanvas}
                        to={`/${store}/cart`}
                        variant="outline-primary"
                    >
                        {t('Browse your cart')}
                    </Button>
                    <Button
                        as={Link}
                        onClick={hideCanvas}
                        to={`/${store}/cart/order`}
                        variant="success"
                        className="checkout"
                        style={{ margin: 5 }}
                    >
                        {t('Continue To Checkout')}
                    </Button>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
})
