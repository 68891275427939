import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Helmet } from 'react-helmet'
import t from 'counterpart'

import { loadCart } from '../actions'

import ShippingMethods from './ShippingMethods/ShippingMethods'
import Addresses from './Addresses/Addresses'
import Locations from './Locations/Locations'
import PaymentMethods from './PaymentMethods'
import Coupon from './Coupon'

import './PlaceOrder.scss'
import { triggerCheckoutGEvent } from '../../Commerce/analytics/g-tags-events-triggers'
import { ProductsList } from './ProductList/ProductsList'
import { Steps } from './Steps'
import { ContactInfo } from './ContactInfo'
import { Summary } from './Summary'
import { DeliveryContactInfo } from './DeliveryContactInfo/DeliveryContactInfo'
import { useProductAndTotal } from './hooks/useProductAndTotal'
import API from '../../../utilities/API'
import { IsBusinessSwitch } from './IsBusinessSwitch/IsBusinessSwitch'

function PlaceOrder() {
    const { cart, portal } = useSelector(({ cart, portal }) => ({ cart, portal }))
    const cartLength = cart.items.length

    const dispatch = useDispatch()
    const [review, setReview] = useState(false)
    const [pointsAvailable, setPointsAvailable] = useState(false)

    // order data
    const [order, setOrder] = useState({
        platform: 'web',
        name: '',
        phone: '',
        email: '',
        delivery_name: '',
        delivery_phone: '',
        delivery_email: '',
        delivery_time: '',
        consent: false,
        shipping_consent: false,
        is_business: false,
        points_cash: 0,
    })

    // set products and totals
    const [products, totals] = useProductAndTotal(cart, order)
    // load initial data
    useEffect(() => dispatch(loadCart()), [dispatch])
    useEffect(() => {
        API(`commerce/loyalty-points/total`, {
            result: (data) => {
                setPointsAvailable(data)
            },
            error: console.log('error'),
        })
    }, [])
    // Set contact information from user
    useEffect(() => {
        if (portal.user) {
            const { full_name, email, mobile } = portal.user || {}
            setOrder((order) => {
                return {
                    ...order,
                    name: full_name,
                    email,
                    phone: mobile,
                    delivery_name: full_name,
                    delivery_email: email,
                    delivery_phone: mobile,
                }
            })
        }
    }, [portal.user])

    useEffect(() => {
        if (cart?.items.length > 0) {
            triggerCheckoutGEvent(cart.items, window.portalSetting('commerce.currency', '', false))
        }
    }, [cart?.items.length, cart])

    // scroll to top when review or error changes
    useEffect(() => window.scroll(0, 0), [review, cart.error])
    //
    const onContactChange = ({ target }) => setOrder({ ...order, [target.name]: target.value })
    const handleShippingMethodChange = (m) => {
        setOrder((order) => {
            let newOrder = {
                ...order,
                shipping_method_id: m.id,
                shippingMethod: m,
            }
            if (!newOrder.shippingMethod?.address_required) {
                delete newOrder.address_id
            }
            return newOrder
        })
    }
    // send order request

    const canUsePoints = window.portalSetting('commerce.loyalty.activate')
    const canEarningPoints = window.portalSetting('commerce.loyalty.points_earning_activate')
    const includeCashback = !(window.portalSetting('commerce.cashback.type') === 'included')
    const total = includeCashback ? totals.total : totals.total - totals.totalCashBack

    return (
        <div
            id="place-order-page"
            className={'container page-body ' + (review ? 'order-review' : '')}
        >
            <Helmet>
                <title>{t('Place Your Order')}</title>
            </Helmet>

            {cartLength === 0 && <h1>{t('There are no items in your cart.')}</h1>}
            {cartLength !== 0 && (
                <div>
                    <Steps />
                    <ProductsList cart={cart} products={products} />

                    {/* contact-info */}
                    <ContactInfo onContactChange={onContactChange} order={order} />
                    <div className="row">
                        {canUsePoints && canEarningPoints && (
                            <div className="points_win col-12">
                                {window.portalSetting('commerce.loyalty.money_to_points') &&
                                    window.portalSetting('commerce.loyalty.money_to_points') !==
                                        '0' &&
                                    (total - (order.points_cash || 0)) *
                                        +window.portalSetting('commerce.loyalty.money_to_points') >=
                                        1 && (
                                        <span className="h6 d-block">
                                            {t('This request will add')}{' '}
                                            {Math.floor(
                                                (total - (order.points_cash || 0)) *
                                                    +window.portalSetting(
                                                        'commerce.loyalty.money_to_points'
                                                    )
                                            ).toLocaleString()}
                                            {t('to your account')}
                                        </span>
                                    )}
                            </div>
                        )}
                        <div className="col-sm-6">
                            {cart.isShippable && (
                                <div className="row">
                                    {/* is_business button */}

                                    <ShippingMethods
                                        title={
                                            <h3>
                                                <span>2</span>
                                                {t('Shipping Method')}
                                            </h3>
                                        }
                                        onChange={handleShippingMethodChange}
                                    />
                                    <div style={{ marginLeft: '15px' }}>
                                        <IsBusinessSwitch
                                            review={review}
                                            isBusiness={order.is_business}
                                            onChange={(is_business) =>
                                                setOrder((order) => ({
                                                    ...order,
                                                    is_business,
                                                }))
                                            }
                                        />
                                    </div>

                                    {(order.shippingMethod?.address_required ||
                                        order.is_business) && (
                                        <Addresses
                                            is_order_business={order.is_business}
                                            onChange={(id) =>
                                                setOrder((order) => ({
                                                    ...order,
                                                    address_id: id,
                                                }))
                                            }
                                            review={review}
                                        />
                                    )}

                                    {!order.shippingMethod?.address_required && (
                                        <Locations
                                            onChange={(id) =>
                                                setOrder((order) => ({
                                                    ...order,
                                                    location_id: id,
                                                }))
                                            }
                                        />
                                    )}
                                    {/* Delivery Contact Information */}
                                    {order.shippingMethod?.address_required && (
                                        <DeliveryContactInfo
                                            onContactChange={onContactChange}
                                            order={order}
                                            review={review}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                        <div className="col-sm-6">
                            <div className="row">
                                <div className="payments-coupon-wrapper">
                                    <PaymentMethods
                                        totals={totals}
                                        onChange={(m) =>
                                            setOrder((order) => ({
                                                ...order,
                                                payment_method_id: m?.id || undefined,
                                                paymentMethod: m || undefined,
                                            }))
                                        }
                                    />
                                </div>
                            </div>

                            <Summary
                                totals={totals}
                                cart={cart}
                                pointsAvailable={pointsAvailable}
                                order={order}
                                setOrder={setOrder}
                                review={review}
                                setReview={setReview}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default PlaceOrder
