import React, { useState } from 'react'
import { PaymentModals } from './types'
import { Button, Form, Modal } from 'react-bootstrap'
import t from 'counterpart'

type CustomAmountModalProps = {
    PaymentModalType: PaymentModals | ''
    setPaymentModalType: Function
    order: any
    proccedToPaymentMethods: Function
}
const CustomAmountModal = ({
    PaymentModalType,
    setPaymentModalType,
    order,
    proccedToPaymentMethods
}: CustomAmountModalProps) => {
    const [HasError, setHasError] = useState<boolean>(false)
    const [Amount, setAmount] = useState<number>(order?.total)

    const handleAmountInputChange = (event: { target: { value: any } }) => {
        setAmount(event.target.value);
        if (event.target.value >= 1 && event.target.value <= order?.total) {
            setHasError(false)
        } else {
            setHasError(true)
        }
    }
    return (
        <div className="summary-modal custom-amount-modal">
            <Modal
                show={PaymentModalType === PaymentModals.CUSTOM}
                dialogClassName="modal-90w m-auto"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header>
                    <div className="modal__header">
                        <div className="modal__header--title">
                            <span className="mb-1">{t('youArePaying')}</span>
                            <small className="text-muted text-small">
                                {t('youCanPayCustomAmount')}
                            </small>
                        </div>
                        <button
                            className="btn btn-close"
                            onClick={() => setPaymentModalType('')}
                        ></button>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="custom-amount-form">
                        <Form.Label htmlFor="cutomAmountInput">
                        {t('yourCustomAmount')}
                            </Form.Label>
                        <Form.Control
                            type="number"
                            id="cutomAmountInput"
                            aria-describedby="customAmount"
                            max={order?.total}
                            min="1"
                            defaultValue={Amount}
                            onChange={handleAmountInputChange}
                        />
                        {HasError && (
                            <small id="customAmount" className="form-text text-danger">
                                {
                                    t('enterCustomAmount')
                                }
                            </small>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        disabled={HasError}
                        className="w-100"
                        variant="primary"
                        onClick={() => proccedToPaymentMethods(Amount)}
                    >
                        {
                                    t('payTheAmount')
                                }
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default CustomAmountModal
