import React from 'react'
import { Route } from 'react-router-dom'

const Builder = React.lazy(() => import('./Builder'))
const Themes = React.lazy(() => import('./Themes'))

const plyInit = {
    reducers: {},
    routes: [
        <Route key="cms_builder" exact path="/cms/builder/:id" element={<Builder />} />,
        <Route key="cms_builder" exact path="/cms/themes" element={<Themes />} />,
    ],
    i18n: {
        en: {
            'Main Structure': 'Main Structure',
            Homepage: 'Home page',
            copy: 'Copy',
            settings: 'Settings',
        },
        ar: {
            'Main Structure': 'الهيكل الرئيسي',
            Homepage: 'الصفحة الرئيسية',
            copy: 'نسخ',
            settings: 'إعدادات',
        },
    },
}

export default plyInit
