import Carousel from '../components/Carousel'
import ProductBox from './ProductBox'

export default function ProductsCarousel(props) {
    return (
        <Carousel
            {...props}
            loadedItems={props.loadedProducts}
            renderItem={(product, key, extended) => (
                <ProductBox
                    key={key}
                    product={product}
                    withDescription={extended}
                    boxStyle={props.boxStyle}
                />
            )}
            apiEndpoint="/commerce/products"
        />
    )
}
