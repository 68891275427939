import React, { useEffect, useState } from 'react'
import { Spinner, Card, Badge, FormCheck, Button, Modal } from 'react-bootstrap'
import api from '../../../utilities/API'
import { useSelector } from 'react-redux'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import t from 'counterpart'

import './style/OrderSummary.scss'
import { PaymentModals } from './types'
import SplittedModalOptions from './SplittedModalOptions'
import DividedModal from './DividedModal'
import CustomAmountModal from './CustomAmountModal'
import { paymentRedirection } from '../../Cart/actions'
import PaymentMethodsModal from './PaymentMethodsModal'
import Tips from './Tips'

const TableOrderSummary = () => {
    const orderTipsValues = [5, 10, 20];
    const navigate = useNavigate()
    const { ready, user } = useSelector((state: any) => state.portal)
    const [order, setOrder] = useState<any>()
    const [PaymentModalType, setPaymentModalType] = useState<PaymentModals | ''>('')
    const [TipAmount, setTipAmount] = useState<number>(0)

    const [TotalOrder, setTotalOrder] = useState<number>(0)
    const [CheckedItems, setCheckedItems] = useState<any[]>([])

    const store = useParams().store

    let { id } = useParams()
    const load = (page = 0) => {
        api(`/commerce/orders/table/${id}`, {
            result: (data: any) => {
                setOrder(data)
                setTotalOrder(data.total)
            },
        })
    }

    const handleSelectItems = (e: any, item: any) => {
        if (e.target.checked) {
            setCheckedItems((prev) => [...prev, item])
        } else {
            setCheckedItems((prev) => prev.filter((i) => i.id !== item.id))
        }
    }

    const handleConfirmPay = (payment_method_id: any) => {
        setPaymentModalType('')
        api(`/commerce/orders/${order?.id}/payment`, {
            method: 'POST',
            data: {
                cash: false,
                amount: previewFinalTotalOrder(),
                tips: orderTipsValues.includes(TipAmount)
                    ? Math.round((TotalOrder * TipAmount) / 100)
                    : TipAmount,
                payment_method_id,
            },
            result: (res: any) => {
                paymentRedirection(res, navigate, store)
            },
        })
    }

    const proccedToPaymentMethods = (amount: number) => {
        setPaymentModalType(PaymentModals.PAYMENT_METHODS)
        setTotalOrder(amount)
    }

    useEffect(() => {
        if (PaymentModalType === PaymentModals.YOURITEMS) {
            setTotalOrder(0)
            setCheckedItems([])
        }
    }, [PaymentModalType])

    useEffect(() => {
        setTotalOrder(CheckedItems.reduce((acc, item) => acc + item.total, 0))
    }, [CheckedItems])

    //
    useEffect(() => {
        if (ready && user) {
            load()
        }
    }, [ready, user])

    const previewFinalTotalOrder = () => {
        return Number(
            TotalOrder +
                (orderTipsValues.includes(TipAmount)
                    ? Math.round((TotalOrder * TipAmount) / 100)
                    : TipAmount)
        ).toFixed(2)
    }

    if (!ready) {
        return (
            <Spinner
                animation="border"
                variant="primary"
                style={{ position: 'fixed', top: '50%', left: '50%' }}
            >
                <span className="sr-only">Loading...</span>
            </Spinner>
        )
    }

    if (!user) {
        return <Navigate to={`/${store}/auth/login`} replace />
    }

    if (!order)
        return (
            <div>
                <p className="text-center m-4"> {t('There are no requests on this table')} </p>
            </div>
        )

    return (
        <section className="splitted__payment container">
            {/* Payment Modals */}
            <SplittedModalOptions {...{ PaymentModalType, setPaymentModalType, order }} />
            <DividedModal
                {...{
                    PaymentModalType,
                    setPaymentModalType,
                    order,
                    setTotalOrder,
                }}
            />
            <CustomAmountModal
                {...{ PaymentModalType, setPaymentModalType, order, proccedToPaymentMethods }}
            />
            <PaymentMethodsModal
                {...{ PaymentModalType, setPaymentModalType, order, handleConfirmPay }}
            />

            <Card className="order__summary p-2 h-100">
                <Card.Title>
                    {t('Table')} #{order?.table_id}
                </Card.Title>
                <Card.Body>
                    <ul className="order__list">
                        {order?.items.map((item: any) => {
                            return (
                                <li key={item.id} className="order__item">
                                    <div className="order__item--info">
                                        {PaymentModalType === PaymentModals.YOURITEMS && (
                                            <div className="CheckBox">
                                                {String(item.total) !== '0' && (
                                                    <FormCheck
                                                        isValid
                                                        onChange={(e) => handleSelectItems(e, item)}
                                                    />
                                                )}
                                            </div>
                                        )}
                                        <Badge className="m-1" pill bg="secondary">
                                            x{item.quantity}
                                        </Badge>
                                        <span className="line-clamp-1 p-2">
                                            {item.product.name}
                                        </span>
                                    </div>
                                    <div style={{ width: '70px', textAlign: 'center' }}>
                                        {item.total ? (
                                            <small className="text-nowrap fw-bold">
                                                {item.total} {t(order?.currency)}
                                            </small>
                                        ) : (
                                            <small className="text-success fw-bold">
                                                {t('Gift')}
                                            </small>
                                        )}{' '}
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                </Card.Body>
                <Card.Footer className="text-right">
                    <div className="h6 mb-0 d-flex justify-content-between">
                        <span>{t('total')}</span>
                        <span>
                            {TotalOrder} {t(order?.currency)}
                        </span>
                    </div>
                </Card.Footer>
            </Card>
            <Tips {...{orderTipsValues, order, TotalOrder, TipAmount, setTipAmount }} />
            <Card className="payment__total mt-2 p-3">
                <Card.Title className="">
                    <div className="h6 mb-0 d-flex justify-content-between">
                        <span>{t('youArePaying')}</span>
                        <span>
                            {previewFinalTotalOrder()} {t(order?.currency)}
                        </span>
                    </div>
                    <small className="text-muted text-small">{t('youCanSplitTheBill')}</small>
                </Card.Title>

                <Card.Body className="d-flex justify-content-center gap-2 p-0">
                    <Button
                        variant="primary"
                        className="flex-grow-1"
                        size="sm"
                        onClick={() => setPaymentModalType(PaymentModals.OPTIONS)}
                    >
                        {t('splitTheBill')}
                    </Button>{' '}
                    <Button
                        disabled={TotalOrder === 0}
                        variant="outline-primary"
                        className="flex-grow-1"
                        size="sm"
                        onClick={() => proccedToPaymentMethods(TotalOrder)}
                    >
                        {t('payTheBill')}
                    </Button>
                </Card.Body>
            </Card>
        </section>
    )
}

export default TableOrderSummary
