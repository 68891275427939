import { useSelector, useDispatch } from 'react-redux'
import { Alert, Button, FormControl } from 'react-bootstrap'

import moment from 'moment'
import t from 'counterpart'
import { changeQuantity, deleteItem } from '../actions'

import '../style/Cart.scss'

import ProductBadges from '../../Commerce/Blocks/ProductBadges'
import { useEffect, useMemo, useState } from 'react'

export const CartItem = ({ item, cashBack, gift = false }) => {
    return (
        <div key={item.cart_id} className={`product ${gift && 'gift'}`}>
            <PriceChangeWarning item={item} />

            <div className="card-info-wrapper">
                <div
                    className="image col-2"
                    style={{ backgroundImage: "url('" + item.thumbnail + "')" }}
                />

                <div className="info-side">
                    <div className="data">
                        <Details item={item} gift={gift} />
                        <PriceBox item={item} cashBack={cashBack} />
                    </div>
                    <Controls item={item} gift={gift} />
                </div>
            </div>
        </div>
    )
}
const Details = ({ item, gift }) => {
    const displayCashback = !!window.portalSetting('commerce.cashback.display')
    const currentPrice =
        displayCashback && item.cash_back ? item.current_price - item.cash_back : item.current_price
    return (
        <div className="details">
            {!gift && <ProductBadges product={item} externalStyles={true} />}
            <h6>{item.name}</h6>
            <h6>{item.parent_name}</h6>
            {item.cart_expiration && (
                <h5 style={{ color: 'red' }}>
                    {t('Expires')}: {moment(item.cart_expiration).fromNow()}
                </h5>
            )}
            {/* <div>
                {item.current_price === 0
                    ? t('Free')
                    : t('price') +
                      ': ' +
                      (item.current_price * item.cart_quantity).toLocaleString() +
                      ' ' +
                      window.portalSetting('commerce.currency')}
            </div> */}

            <div>
                {!item.current_price && <div>{t('Free')}</div>}
                {!!item.current_price && (
                    <div className="price-amount">
                        <span>
                            <span>{currentPrice.toLocaleString()}</span>{' '}
                            {window.portalSetting('commerce.currency')}{' '}
                        </span>
                        {item.saved_amount || (item.cash_back && displayCashback) ? (
                            <span className="old-price">
                                {item.price.toLocaleString() +
                                    ' ' +
                                    window.portalSetting('commerce.currency')}
                            </span>
                        ) : (
                            ''
                        )}
                    </div>
                )}
            </div>

            {!!item.cart_options &&
                item.cart_options.map((option, i) => (
                    <div key={i}>
                        {option.key +
                            ': ' +
                            ' ' +
                            (option.price * item.cart_quantity).toLocaleString() +
                            ' ' +
                            window.portalSetting('commerce.currency')}
                    </div>
                ))}
        </div>
    )
}
const PriceBox = ({ item }) => {
    const displayCashback = !!window.portalSetting('commerce.cashback.display')
    const cartOptionsPrice = useMemo(
        () => (item.cart_options || []).reduce((acc, option) => acc + Number(option.price), 0),
        [item.cart_options]
    )
    const currentPrice =
        displayCashback && item.cash_back
            ? item.current_price - item.cash_back + (cartOptionsPrice || 0)
            : item.current_price + (cartOptionsPrice || 0)
    return (
        <div className="price-box ">
            <div className="price-wrapper">
                {!item.current_price && <div>{t('Free')}</div>}
                {!!item.current_price && (
                    <div className="price-amount">
                        <span>
                            <span>{(currentPrice * item.cart_quantity).toLocaleString()}</span>{' '}
                            {window.portalSetting('commerce.currency')}{' '}
                        </span>
                        {item.saved_amount || (item.cash_back && displayCashback) ? (
                            <span className="old-price">
                                {(item.price * item.cart_quantity).toLocaleString() +
                                    ' ' +
                                    window.portalSetting('commerce.currency')}
                            </span>
                        ) : (
                            ''
                        )}
                    </div>
                )}
            </div>
            <div className="savings-wrapper">
                {!!item.saved_amount && (
                    <div className="saved-amount">
                        {t('saved_amount')} {item.saved_amount * item.cart_quantity}{' '}
                        {window.portalSetting('commerce.currency')}
                    </div>
                )}
                {!!item.cash_back && !displayCashback && (
                    <div className="saved-amount">
                        {!!item.saved_amount && <span>, </span>}
                        {t('With %(amount)s %(currency)s cashback', {
                            amount: item.cash_back * item.cart_quantity,
                            currency: window.portalSetting('commerce.currency'),
                        })}
                    </div>
                )}
            </div>
            {!!item.current_pric && <div className="note">{t('VAT Included')}</div>}
        </div>
    )
}

const Controls = ({ item, gift }) => {
    const dispatch = useDispatch()
    const user = useSelector((state) => state.portal.user)
    const localCart = !user

    const [quantity, setQuantity] = useState(item.cart_quantity)
    const [debouncedQuantity, setDebouncedQuantity] = useState(quantity)

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedQuantity(quantity)
        }, 500)

        return () => {
            clearTimeout(handler)
        }
    }, [quantity])

    useEffect(() => {
        if (debouncedQuantity !== item.cart_quantity) {
            dispatch(changeQuantity(item.cart_id, debouncedQuantity, localCart))
        }
    }, [debouncedQuantity, dispatch, item.cart_id, item.cart_quantity, localCart])

    const handleQuantityChange = (e) => {
        const newQuantity = e.target.value
        setQuantity(newQuantity)
    }

    return (
        <div className="controls">
            <div>
                {!gift && (
                    <Button
                        className="delete"
                        onClick={() => dispatch(deleteItem(item, localCart))}
                    >
                        <i className="fa fa-trash" /> {t('Remove')}
                    </Button>
                )}
            </div>
            <div className="quantity">
                <span>{t('Quantity')}</span>
                <FormControl
                    type="number"
                    min="1"
                    value={quantity}
                    onChange={handleQuantityChange}
                    disabled={gift}
                />
            </div>
        </div>
    )
}

const PriceChangeWarning = ({ item }) => (
    <>
        {!!item.cart_price && item.cart_price !== item.current_price && (
            <Alert variant="warning">
                <i className="fa fa-warning" style={{ marginLeft: 5 }} />
                {t('This item price changed since you add it to your cart')} (
                {(item.current_price - item.cart_price || 0).toLocaleString()})
            </Alert>
        )}
    </>
)
