import React, { useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Button, FormControl, InputGroup } from 'react-bootstrap'
import * as t from 'counterpart'
import api from '../../../utilities/API'

import './style/Search.scss'

let timer
let imagePrefix = process.env.REACT_APP_IMAGES_PREFIX || "";

export default React.forwardRef(function (props = {}, ref) {
    const [search, setSearch] = React.useState('')
    const navigate = useNavigate()
    const { store } = useParams()
    const [autoCompleteData, setAutoCompleteData] = useState([])

    props = {
        ...props,
        className: 'search-block ' + props.className,
    }

    //
    const loadData = (search) => {
        if (!search) {
            setAutoCompleteData([])
            return
        }

        api('/commerce/products/?limit=10&search=' + search, {
            result: ({ data }) => setAutoCompleteData(data || []),
        })
    }

    //
    const onChange = (e) => {
        const newSearch = e.target.value
        setSearch(newSearch)

        // auto complete
        clearTimeout(timer)
        timer = setTimeout(() => loadData(newSearch), 500)
    }

    //
    const go = () => navigate(`/${store}/browse?search=${search}`)

    return (
        <div {...props} ref={ref}>
            <InputGroup>
                <FormControl
                    placeholder={t('What are you looking for?')}
                    value={search}
                    onChange={onChange}
                    onKeyPress={(e) => (e.key === 'Enter' ? go() : '')}
                    onBlur={() => setTimeout(() => setAutoCompleteData([]), 300)}
                />

                <div className="auto-complete">
                    {autoCompleteData &&
                        autoCompleteData.map((product) => (
                            <Link
                                to={`/${store}/details/${product.slug}-${product.id}`}
                                key={product.id}
                                onClick={() => setAutoCompleteData([])}
                            >
                                <div
                                    className="image"
                                    style={{
                                        backgroundImage:
                                            "url('" + imagePrefix + product.thumbnail + "')",
                                            backgroundRepeat: "no-repeat",
                                            backgroundPosition: "center",
                                    }}
                                />
                                {product.name}
                            </Link>
                        ))}
                </div>

                <Button onClick={go}>
                    <i className="fa fa-search" />
                </Button>
            </InputGroup>
        </div>
    )
})
