const i18n = {
    ar: {
        dir: 'rtl',
        Neighborhood: 'الحي',
        'Postal Code': 'الرمز البريدي',
        'Plot Number': 'رقم قطعة الأرض',
        'Registry Name': 'إسم السجل',
        'Registry Number': 'رقم السجل',
        'Tax Number': 'الرقم الضريبى',
        left: 'right',
        right: 'left',
        "Don't have an account? get one in few minutes":
            'لا تملك حساب؟ يمكنك إنشاء حساب جديد في دقائق',
        'Get access to your orders, favorites and recommendations':
            'لتصل إلي طلباتك وقائمتك المفضلة وتوصياتك للمنتجات التي تهتم بها',
        'First Name': 'الإسم الأول',
        'Last Name': 'الإسم الأخير',
        Password: 'كلمة المرور',
        Email: 'البريد الإلكتروني',
        Mobile: 'الهاتف الجوال',
        Register: 'تسجيل حساب جديد',
        'Do you have an account?': 'هل أنت عضو بالفعل؟',
        Login: 'تسجيل الدخول',
        'Did you forget your password? Type your email to change it':
            'هل نسيت كلمة المرور؟ قم بطلب تغييرها',
        'Change Password': 'تغيير كلمة المرور',
        "Didn't forget the password?": 'لا تحتاج تغيير كلمة المرور؟',
        'A confirmation code has been sent to your email':
            ' تم إرسال كود التأكيد إلي بريدك الإلكتروني',
        'Invalid confirmation code': 'كود التفعيل منتهي او غير صحيح',
        'Confirmation Code': 'كود التأكيد',
        'New Password': 'كلمة المرور الجديدة',
        'Password Confirmation': 'إعادة كلمة المرور',
        'Your account have been successfully created': 'تم تسجيل العضوية بنجاح',
        'Last step, Confirm your account by typing the code you received':
            'تبقي خطوة واحده، تفعيل الحساب عن طريق الكود المرسل',
        Confirm: 'تأكيد',
        'There are no data right now': 'لا يوجد بيانات لعرضها حالياً',
        More: 'المزيد',
        By: 'بواسطة',
        Delete: 'حذف',
        "Don't have account?": 'لا تملك حساب؟',
        'Register Now': 'سجل الأن',
        'Forget Password': 'نسيت كلمة المرور',
        Back: 'العودة',
        'Email / Username': 'إسم المستخدم / البريد الإلكتروني',
        'My Account': 'حسابي',
        'Resend Code': 'إعادة إرسال الكود',
        users: 'العضويات',
        profile: 'الملف الشخصي',
        setting: 'الإعدادات',
        Notifications: 'الإشعارات',
        Warning: 'تنبيه',
        confirm: 'تأكيد',
        All: 'الكل',
        'Please fill out the required fields': 'برجاء تعبئة الحقول المطلوبة',
        'Something went wrong, Please try again': 'حدث خطأ، برجاء المحاولة مرة أخري',
        Cancel: 'إلغاء',
        'Are you sure?': 'هل أنت متأكد؟',
        username: 'إسم المستخدم',
        password: 'كلمة المرور',
        Expires: 'ينتهي',
        'Manage your logged-in devices': 'إدارة جلسات الدخول',
        'Account Setting': 'إعدادات الحساب',
        Days: 'أيام',
        Hours: 'ساعات',
        Minutes: 'دقائق',
        Seconds: 'ثواني',
        full_name: 'الإسم الكامل',
        first_name: 'الإسم الأول',
        last_name: 'الإسم الأخير',
        mobile: 'الجوال',
        role_ids: 'الصلاحيات',
        email_activated_at: 'تاريخ تفعيل الحساب',
        'General Setting': 'الإعدادات العامة',
        'Welcome Back': 'مرحبًا بعودتك',
        'Use Mobile Number': 'إستخدم رقم الجوال',
        'Use Email': 'إستخدم البريد الإلكتروني',
        phone: 'الجوال',
        'Validation error': 'يجب تعبئة جميع الحقول ببيانات سليمة',
        day: 'يوم',
        Copy: 'نسخ',
        registeration_id: 'رقم التسجيل',
        tax_id: 'الرقم الضريبي',
        
        tax_percent: 'نسبة الضريبة',
        "1activeـproducts": 'نشطه',
        "2inactiveـproducts": 'مسودة',
        "3totalـproducts": "المنتجات",
        "4availableـproducts": 'متوفر',
        "5unavailableـproducts": "نفذت الكميه",
        
        'There are no requests on this table': 'لا توجد طلبات علي هذه الطاوله',
        'Would you like to add a tip ?': 'هل ترغب بإضافة إكراميه ؟',
        'custom tip': 'إكرامية مخصصه',
        SAR: 'ريال',
        'all tip amount are in': 'جميع الإكراميات بال',
    },
    en: {
        dir: 'ltr',
        left: 'left',
        right: 'right',
        permissions_cor: 'System Permissions',
        full_name: 'Full Name',
        first_name: 'First Name',
        last_name: 'Last Name',
        email_activated_at: 'Account Activated At',
        "1active_products": 'Active',
        "2inactive_products": 'Draft',
        "3total_products": "Products",
        "4available_products": 'Available',
        "5unavailable_products": "Out of Stock",
    },
}

export default i18n
