import { useEffect, useState, useCallback, useMemo } from 'react'
import t from 'counterpart'
import api from '../../../../utilities/API'
import './Addresses.scss'
import { AddressForm } from './AddressForm'
import { AddressDTO } from '../../../../Types/DTOs/AddressDTO'
import { AddressesProps, NewAddressFormType } from './types'
// adding this directly shows error

const INITIAL_ADDRESS_FORM = {
    name: '',
    country: '',
    state: '',
    city: '',
    street: '',
    building: '',
    floor: '',
    apartment: '',
    neighborhood: '',
    postal_code: '',
    plot_number: '',
    registry_name: '',
    tax_number: '',
    registry_number: '',
    lat: null,
    lng: null,
    default: false,
}

const Addresses = ({ is_order_business, onChange, review = false }: AddressesProps) => {
    const [addresses, setAddresses] = useState<AddressDTO[]>([])
    const [selectedId, setSelectedId] = useState<number | null>(null)
    const [newAddressForm, setNewAddressForm] = useState<NewAddressFormType>(INITIAL_ADDRESS_FORM)

    const handleAddressChange = useCallback((addrId: number | null) => {
        setSelectedId(addrId)
        if (onChange) {
            onChange(addrId)
        }
    }, [])

    // load initial data
    useEffect(() => {
        api('commerce/addresses/', {
            result: ({ data }: { data: AddressDTO[] }) => {
                if (!data || data.length === 0) {
                    setAddresses([])
                    handleAddressChange(null) // You might want to handle this in your function
                    return
                }

                setAddresses(handleAddressReordering(data))

                // Handle the change with the selected address
            },
            error: alert,
        })
    }, [handleAddressChange])

    const onAddressFormChange = ({ target }: { target: { name: string; value: string } }) => {
        setNewAddressForm((prevSelected: any) => ({ ...prevSelected, [target.name]: target.value }))
    }
    const onSubmitAddressSucess = (data: AddressDTO) => {
        setAddresses((prevAddress) => [data, ...prevAddress])
        setNewAddressForm(() => INITIAL_ADDRESS_FORM)
        handleAddressChange(data.id)
    }

    const handleAddressReordering = useCallback(
        (filteredAddresses: AddressDTO[]) => {
            const selectedAddress = filteredAddresses.find((i) => i.default) || filteredAddresses[0]

            const addressWithoutSelected = filteredAddresses.filter(
                (i) => selectedAddress.id !== i.id
            )

            if (selectedAddress) {
                handleAddressChange(selectedAddress.id)
                return [selectedAddress, ...addressWithoutSelected]
            }
            handleAddressChange(null)
            return filteredAddresses
        },
        [handleAddressChange]
    )
    const selectedAddresses = useMemo(() => {
        const filtedAddresses = is_order_business
            ? addresses.filter(
                  (addr) => addr.registry_name && addr.registry_number && addr.tax_number
              )
            : addresses
        return handleAddressReordering(filtedAddresses)
    }, [addresses, is_order_business, handleAddressReordering])

    return (
        <div id="address-wrapper">
            <h3>
                <span>
                    <i className="fa fa-location" />
                </span>
                {t('Shipping Address')}
            </h3>
            <div id="addresses">
                {selectedAddresses.map((addr) => (
                    <div
                        key={addr.id}
                        className={'address ' + (addr.id === selectedId ? 'selected' : '')}
                        onClick={review ? undefined : () => handleAddressChange(addr.id)}
                    >
                        <div>
                            <h6>{addr.name}</h6>
                            {addr.country} - {addr.state} - {addr.city} <br />
                            {addr.street} {addr.building}, {addr.floor}, {addr.apartment}
                        </div>
                    </div>
                ))}
            </div>
            {selectedId && (
                <div onClick={() => handleAddressChange(null)} className="new-address-button">
                    <i className="fa fa-plus" /> {t('Add New Address')}
                </div>
            )}

            {!selectedId && (
                <div id="new-address" className="row">
                    <AddressForm
                        selectedId={selectedId}
                        setNewAddressForm={setNewAddressForm}
                        is_order_business={is_order_business}
                        newAddressForm={newAddressForm}
                        onAddressFormChange={onAddressFormChange}
                        onSubmitAddressSucess={onSubmitAddressSucess}
                    />
                </div>
            )}
        </div>
    )
}

//
export function parseAddress(p: google.maps.places.PlaceResult) {
    let addr = {
        name: p.formatted_address,
        country: '',
        state: '',
        city: '',
        street: '',
        building: '',
        floor: '',
        apartment: '',
        default: false,
    }

    p.address_components?.forEach((component: any) => {
        component.types.forEach((type: any) => {
            if (type === 'country' && component.types.includes('political')) {
                addr.country = component.short_name
            } else if (
                type === 'administrative_area_level_1' &&
                component.types.includes('political')
            ) {
                addr.state = component.short_name
            } else if (
                (type === 'administrative_area_level_2' || type === 'locality') &&
                component.types.includes('political')
            ) {
                addr.city = component.short_name
            } else if (type === 'route') {
                addr.street = component.long_name
            }
        })
    })

    return addr
}

export default Addresses
